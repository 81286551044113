export enum TStorageNames {
  user = 'user',
  confirmedAnimalTypes = 'confirmedAnimalTypes',
}

type TStorage = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getItem: (key: TStorageNames) => any; // Todo: find solution
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setItem: (key: TStorageNames, value: any) => void; // Todo: find solution
  removeItem: (key: TStorageNames) => void;
  clear: () => void;
};

const oppSessionStorage: TStorage = {
  getItem: key => {
    const item = sessionStorage.getItem(key);
    return item ? JSON.parse(item) : null;
  },
  setItem: (key, value) => {
    try {
      sessionStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.log(error);
    }
  },
  removeItem: key => {
    try {
      sessionStorage.removeItem(key);
    } catch (error) {
      console.log(error);
    }
  },
  clear: () => {
    try {
      sessionStorage.clear();
    } catch (error) {
      console.log(error);
    }
  },
};
const oppLocalStorage: TStorage = {
  getItem: key => {
    const item = localStorage.getItem(key);
    return item ? JSON.parse(item) : null;
  },
  setItem: (key, value) => {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.log(error);
    }
  },
  removeItem: key => {
    try {
      localStorage.removeItem(key);
    } catch (error) {
      console.log(error);
    }
  },
  clear: () => {
    try {
      localStorage.clear();
    } catch (error) {
      console.log(error);
    }
  },
};

export { oppLocalStorage, oppSessionStorage };
