import { TCreateConverter, TFetchConverter } from 'data/types/convertor.types';
import {
  TOnboardingAddAplicationDB,
  TOnboardingAddAplicationUI,
  TOnboardingCompleteDB,
  TOnboardingCompleteUI,
  TOnboardingCreateApplicationDB,
  TOnboardingCreateApplicationUI,
  TOnboardingConfirmPaymentDB,
  TOnboardingPaymentAnimalDataDB,
  TOnboardingPaymentAnimalDataUI,
  TOnboardingPaymentDataDB,
  TOnboardingPaymentDataUI,
  TOnboardingConfirmPaymentUI,
  TOnboardingRemoveAplicationDB,
  TOnboardingRemoveAplicationUI,
} from 'data/types/onboarding.types';
import { defaultDateFormat } from 'helpers/utils';
import moment from 'moment';

export const onboardingAddAplicationConvertor: TCreateConverter<
  TOnboardingAddAplicationUI,
  TOnboardingAddAplicationDB
> = {
  toDb: data => ({
    animalId: data.animalId,
    onboardingStateId: data.onboardingStateId,
    moveInDate: data.moveInDate && data.moveInDate.format('YYYY-MM-DD'),
    missingMoveInDateReason: data.missingMoveInDateReason,
  }),
};

export const onboardingRemoveAplicationConvertor: TCreateConverter<
  TOnboardingRemoveAplicationUI,
  TOnboardingRemoveAplicationDB
> = {
  toDb: data => ({
    animalId: data.animalId,
    onboardingStateId: data.onboardingStateId,
  }),
};

export const onboardingCompleteConvertor: TCreateConverter<
  TOnboardingCompleteUI,
  TOnboardingCompleteDB
> = {
  toDb: data => ({
    tenantResidencyId: data.residencyId,
  }),
};

export const onboardingCreateApplicationConvertor: TCreateConverter<
  TOnboardingCreateApplicationUI,
  TOnboardingCreateApplicationDB
> = {
  toDb: data => ({
    onboardingStateId: data.onboardingStateId,
  }),
};

export const numberToTextMapper: Record<number, string> = {
  1: 'First',
  2: 'Second',
  3: 'Third',
  4: 'Fourth',
  5: 'Fifth',
  6: 'Sixth',
  7: 'Seventh',
  8: 'Eighth',
  9: 'Ninth',
  10: 'Tenth',
};

export const onboardingPaymentAnimalDataConvertor: TFetchConverter<
  TOnboardingPaymentAnimalDataUI,
  TOnboardingPaymentAnimalDataDB
> = {
  fromDb: data => {
    return {
      amount: data.currentPrice && data.currentPrice / 100,
      payedUntil: data.payedUntil,
      name: data.animalName,
      isPaid: data.isPaid,
      animalId: data.animalId,
    };
  },
};

export const onboardingPaymentDataConvertor: TFetchConverter<
  TOnboardingPaymentDataUI,
  TOnboardingPaymentDataDB
> = {
  fromDb: data => {
    return {
      animals: data.animals.map(item =>
        onboardingPaymentAnimalDataConvertor.fromDb(item),
      ),
      nextPaymentDate:
        (data.nextPaymentDate &&
          moment(data.nextPaymentDate).format(defaultDateFormat)) ||
        '',
      totalAmount: data.totalPaymentAmount && data.totalPaymentAmount / 100,
      paymentMethod: data.paymentMethod,
    };
  },
};

export const onboardingConfirmPaymentConvertor: TCreateConverter<
  TOnboardingConfirmPaymentUI,
  TOnboardingConfirmPaymentDB
> = {
  toDb: data => ({
    animalIds: data.animalIds,
    leasePacketId: data.leasePacketId,
    propertyName: data.propertyName,
  }),
};
